import { ThemeInterfaceProps } from "@k2/utils";

export const theme: ThemeInterfaceProps = {
  light: {
    backgroundColors: {
      main: "#d0e3e8",
      card: "#fff",
      primary: "#19CDD7",
      secondary: "#f5f5f5",
      info: "#ffffff",
      error: "#fff2f2",
      success: "#e4f7ec",
      warning: "#fef7e3",
      tooltip: "rgba(255, 255, 255, 0.89)",
    },
    borderColors: {
      info: "#6098f3",
      error: "#ff9a9a",
      success: "#70cb98",
      warning: "#e4c66b",
      card: "transparent",
    },
    textColors: {
      info: "#777777",
      error: "#cc4e4e",
      success: "#00c972",
      warning: "#9d7f21",
      normal: "#888888",
      heading: "#000000",
      tooltip: "#737373",
    },
    boxShadowColors: {
      card: "rgba(178, 178, 178, 0.29)",
    },
  },
  dark: {
    backgroundColors: {
      main: "#070d19",
      card: "#0c1427",
      primary: "#f1227f",
      secondary: "#0c1427",
      info: "#81d4fa",
      error: "#ef9a9a",
      success: "#a5d6a7",
      warning: "#ffcc80",
      tooltip: "#070d19",
    },
    borderColors: {
      info: "#86d0f1",
      error: "#ab4e4e",
      success: "#43815e",
      warning: "#705c21",
      card: "#1b284b",
    },
    textColors: {
      info: "#eef4ff",
      error: "#6e0808",
      success: "#00c972",
      warning: "#645114",
      normal: "#f1f1f1",
      heading: "#eef4ff",
      tooltip: "#eef4ff",
    },
    boxShadowColors: {
      card: "transparent",
    },
  },
  common: {
    fontSizes: {
      small: "11px",
      normal: "13px",
      large: "14px",
      xLarge: "18px",
    },
    borderWidth: "0",
    textFontFamily: "roboto, sans-serif",
    numberFontFamily: "Lato, sans-serif",
    letterSpacing: "normal",
  },
  gridLayout: {
    layout: [
      { widthUnits: 2 },
      { widthUnits: 1 },
      { widthUnits: 1 },
      { widthUnits: 1 },
      { widthUnits: 1 },
      { widthUnits: 1 },
      { widthUnits: 2, heightUnits: 2 },
      { widthUnits: 1 },
      { widthUnits: 2, heightUnits: 2 },
      { widthUnits: 1 },
      { widthUnits: 1 },
      { widthUnits: 1 },
      { widthUnits: { xl: 1, lg: 2 } },
    ],
    rowHeight: 300,
    noOfCols: 3,
    gridGap: [15, 15],
  },
  card: {
    borderRadius: "3px",
    bodyPadding: "20px",
    headerPadding: "10px 20px",
    hasBorder: true,
    hasBoxShadow: true,
    hasHeaderBorder: false,
  },
};
